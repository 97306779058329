
        @keyframes load-line {
            0% {
                width: 5%;
                left: 1%;
            }
            20% {
                width: 15%;
                left: 15%;
            }
            40% {
                width: 35%;
                left: 20%;
            }
            60% {
                width: 40%;
                left: 40%;
            }
            80% {
                width: 30%;
                left: 70%;
            }
            100% {
                width: 8%;
                left: 92%;
            }
        }

        .animated-load {
            animation: load-line 1s infinite;
        }
