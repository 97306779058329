c-wiz {
    contain: style;
  }

  c-wiz > c-data {
    display: none;
  }

  :root {
    --wf-tfs: calc(var(--c-tfs, 32) / 16 * 1rem);
    --wf-tfs-bp2: calc(var(--c-tfs, 36) / 16 * 1rem);
    --wf-tfs-bp3: calc(var(--c-tfs, 36) / 16 * 1rem);
    --wf-tfs-bp5: calc(var(--c-tfs, 44) / 16 * 1rem);
    --wf-stfs: calc(var(--c-stfs, 16) / 16 * 1rem);
    --wf-stfs-bp5: calc(var(--c-stfs, 16) / 16 * 1rem);
  }

  .ObDc3 {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  @media (min-width: 840px) {
    .ObDc3 {
      margin-bottom: 32px;
    }
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .ObDc3 {
      margin-bottom: 0;
    }
  }

  .vAV9bf {
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-weight: 400;
    font-weight: var(--c-tfwt, 400);
    line-height: 1.25;
    margin-bottom: 0;
    margin-bottom: var(--c-ts-b, 0);
    margin-top: 24px;
    margin-top: var(--c-ts-t, 24px);
    word-break: break-word;
    font-size: 2rem;
    font-size: var(--wf-tfs, 2rem);
  }

  @media (min-width: 840px) {
    .vAV9bf {
      line-height: 1.22222222;
      font-size: 2.25rem;
      font-size: var(--wf-tfs-bp3, 2.25rem);
    }
  }

  @media (min-width: 960px) {
    .vAV9bf {
      line-height: 1.22222222;
      font-size: 2.25rem;
      font-size: var(--wf-tfs-bp3, 2.25rem);
    }
  }

  @media (min-width: 1600px) {
    .vAV9bf {
      line-height: 1.18181818;
      font-size: 2.75rem;
      font-size: var(--wf-tfs-bp5, 2.75rem);
    }
  }

  .gNJDp {
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-weight: 400;
    font-weight: var(--c-stfwt, 400);
    letter-spacing: 0rem;
    line-height: 1.5;
    margin-bottom: 0;
    margin-bottom: var(--c-sts-b, 0);
    margin-top: 16px;
    margin-top: var(--c-sts-t, 16px);
    font-size: 1rem;
    font-size: var(--wf-stfs, 1rem);
  }

  @media (min-width: 1600px) {
    .gNJDp {
      line-height: 1.5;
      font-size: 1rem;
      font-size: var(--wf-stfs-bp5, 1rem);
    }
  }

  .gNJDp:empty {
    display: none;
  }

  .VfPpkd-dgl2Hf-ppHlrf-sM5MNb {
    display: inline;
  }

  .VfPpkd-LgbsSe {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 64px;
    border: none;
    outline: none;
    line-height: inherit;
    user-select: none;
    -webkit-appearance: none;
    overflow: visible;
    vertical-align: middle;
    background: transparent;
  }

  .VfPpkd-LgbsSe:active {
    outline: none;
  }

  .VfPpkd-LgbsSe:hover {
    cursor: pointer;
  }

  .VfPpkd-LgbsSe:disabled {
    cursor: default;
    pointer-events: none;
  }

  .VfPpkd-LgbsSe .VfPpkd-vQzf8d {
    position: relative;
  }

  .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
    pointer-events: none;
    border: 2px solid transparent;
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    display: none;
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
      border-color: CanvasText;
    }
  }

  .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
    content: "";
    border: 2px solid transparent;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
      border-color: CanvasText;
    }
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-J1Ukfc-LhBDec {
      display: block;
    }
  }

  .VfPpkd-LgbsSe .VfPpkd-RLmnJb {
    position: absolute;
    top: 50%;
    height: 48px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .VfPpkd-LgbsSe-OWXEXe-dgl2Hf {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .VfPpkd-LgbsSe {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
  }

  .VfPpkd-LgbsSe {
    padding: 0 8px 0 8px;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ {
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 16px 0 16px;
  }

  .VfPpkd-LgbsSe {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
    z-index: 0;
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before,
  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  .VfPpkd-Jh9lGc {
    position: absolute;
    box-sizing: content-box;
    overflow: hidden;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .VfPpkd-LgbsSe {
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.0892857143em;
    font-weight: 500;
    text-transform: uppercase;
    height: 36px;
    border-radius: 4px;
  }

  .VfPpkd-LgbsSe:not(:disabled) {
    color: #6200ee;
  }

  .VfPpkd-LgbsSe:disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::before {
    background-color: #6200ee;
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc::after {
    background-color: #6200ee;
  }

  .VfPpkd-LgbsSe:hover .VfPpkd-Jh9lGc::before {
    opacity: 0.04;
  }

  .VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0.12;
  }

  .VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
    transition: opacity 0.15s linear;
  }

  .VfPpkd-LgbsSe:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0.12;
  }

  .VfPpkd-LgbsSe .VfPpkd-Jh9lGc {
    border-radius: 4px;
  }

  .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec {
    border-radius: 2px;
  }

  .VfPpkd-LgbsSe .VfPpkd-J1Ukfc-LhBDec::after {
    border-radius: 4px;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ {
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.0892857143em;
    font-weight: 500;
    text-transform: uppercase;
    height: 36px;
    border-radius: 4px;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(:disabled) {
    background-color: #6200ee;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(:disabled) {
    color: #fff;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:disabled {
    color: rgba(0, 0, 0, 0.38);
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-Jh9lGc::before {
    background-color: #fff;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-Jh9lGc::after {
    background-color: #fff;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:hover .VfPpkd-Jh9lGc::before {
    opacity: 0.08;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(.VfPpkd-ksKsZd-mWPk3d):focus
    .VfPpkd-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0.24;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(.VfPpkd-ksKsZd-mWPk3d)
    .VfPpkd-Jh9lGc::after {
    transition: opacity 0.15s linear;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ:not(.VfPpkd-ksKsZd-mWPk3d):active
    .VfPpkd-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0.24;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-Jh9lGc {
    border-radius: 4px;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-J1Ukfc-LhBDec {
    border-radius: 2px;
  }

  .VfPpkd-LgbsSe-OWXEXe-k8QpJ .VfPpkd-J1Ukfc-LhBDec::after {
    border-radius: 4px;
  }

  .nCP5yc {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.0107142857em;
    font-weight: 500;
    text-transform: none;
    transition: border 0.28s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: none;
  }

  .nCP5yc .VfPpkd-Jh9lGc {
    height: 100%;
    position: absolute;
    overflow: hidden;
    width: 100%;
    z-index: 0;
  }

  .nCP5yc:not(:disabled) {
    background-color: rgb(26, 115, 232);
    background-color: var(--gm-fillbutton-container-color, rgb(26, 115, 232));
  }

  .nCP5yc:not(:disabled) {
    color: #fff;
    color: var(--gm-fillbutton-ink-color, #fff);
  }

  .nCP5yc:disabled {
    background-color: rgba(60, 64, 67, 0.12);
    background-color: var(
      --gm-fillbutton-disabled-container-color,
      rgba(60, 64, 67, 0.12)
    );
  }

  .nCP5yc:disabled {
    color: rgba(60, 64, 67, 0.38);
    color: var(--gm-fillbutton-disabled-ink-color, rgba(60, 64, 67, 0.38));
  }

  .nCP5yc .VfPpkd-Jh9lGc::before,
  .nCP5yc .VfPpkd-Jh9lGc::after {
    background-color: rgb(32, 33, 36);
    background-color: var(--gm-fillbutton-state-color, rgb(32, 33, 36));
  }

  .nCP5yc:hover .VfPpkd-Jh9lGc::before {
    opacity: 0.16;
    opacity: var(--mdc-ripple-hover-opacity, 0.16);
  }

  .nCP5yc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0.24;
    opacity: var(--mdc-ripple-focus-opacity, 0.24);
  }

  .nCP5yc:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
    transition: opacity 0.15s linear;
  }

  .nCP5yc:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0.2;
    opacity: var(--mdc-ripple-press-opacity, 0.2);
  }

  .nCP5yc:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: 0 1px 2px 0
        var(--gm-fillbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
      0 1px 3px 1px
        var(--gm-fillbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
  }

  .nCP5yc:active {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 2px 6px 2px rgba(60, 64, 67, 0.15);
    box-shadow: 0 1px 2px 0
        var(--gm-fillbutton-keyshadow-color, rgba(60, 64, 67, 0.3)),
      0 2px 6px 2px
        var(--gm-fillbutton-ambientshadow-color, rgba(60, 64, 67, 0.15));
  }

  .nCP5yc:disabled {
    box-shadow: none;
  }

  .nCP5yc:disabled:hover .VfPpkd-Jh9lGc::before {
    opacity: 0;
    opacity: var(--mdc-ripple-hover-opacity, 0);
  }

  .nCP5yc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0;
    opacity: var(--mdc-ripple-focus-opacity, 0);
  }

  .nCP5yc:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
    transition: opacity 0.15s linear;
  }

  .nCP5yc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0;
    opacity: var(--mdc-ripple-press-opacity, 0);
  }

  .ksBjEc {
    font-family: "Google Sans", Roboto, Arial, sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.0107142857em;
    font-weight: 500;
    text-transform: none;
  }

  .ksBjEc .VfPpkd-Jh9lGc {
    height: 100%;
    position: absolute;
    overflow: hidden;
    width: 100%;
    z-index: 0;
  }

  .ksBjEc:not(:disabled) {
    background-color: transparent;
  }

  .ksBjEc:not(:disabled) {
    color: rgb(26, 115, 232);
    color: var(--gm-colortextbutton-ink-color, rgb(26, 115, 232));
  }

  .ksBjEc:disabled {
    color: rgba(60, 64, 67, 0.38);
    color: var(
      --gm-colortextbutton-disabled-ink-color,
      rgba(60, 64, 67, 0.38)
    );
  }

  .ksBjEc:hover:not(:disabled),
  .ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d):focus:not(:disabled),
  .ksBjEc:active:not(:disabled) {
    color: rgb(23, 78, 166);
    color: var(--gm-colortextbutton-ink-color--stateful, rgb(23, 78, 166));
  }

  .ksBjEc .VfPpkd-Jh9lGc::before,
  .ksBjEc .VfPpkd-Jh9lGc::after {
    background-color: rgb(26, 115, 232);
    background-color: var(
      --gm-colortextbutton-state-color,
      rgb(26, 115, 232)
    );
  }

  .ksBjEc:hover .VfPpkd-Jh9lGc::before {
    opacity: 0.04;
    opacity: var(--mdc-ripple-hover-opacity, 0.04);
  }

  .ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-focus-opacity, 0.12);
  }

  .ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
    transition: opacity 0.15s linear;
  }

  .ksBjEc:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-press-opacity, 0.12);
  }

  .ksBjEc:disabled:hover .VfPpkd-Jh9lGc::before {
    opacity: 0;
    opacity: var(--mdc-ripple-hover-opacity, 0);
  }

  .ksBjEc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):focus .VfPpkd-Jh9lGc::before {
    transition-duration: 75ms;
    opacity: 0;
    opacity: var(--mdc-ripple-focus-opacity, 0);
  }

  .ksBjEc:disabled:not(.VfPpkd-ksKsZd-mWPk3d) .VfPpkd-Jh9lGc::after {
    transition: opacity 0.15s linear;
  }

  .ksBjEc:disabled:not(.VfPpkd-ksKsZd-mWPk3d):active .VfPpkd-Jh9lGc::after {
    transition-duration: 75ms;
    opacity: 0;
    opacity: var(--mdc-ripple-press-opacity, 0);
  }

  .DuMIQc {
    padding: 0 24px 0 24px;
  }

  .LQeN7 .VfPpkd-J1Ukfc-LhBDec {
    pointer-events: none;
    border: 2px solid rgb(24, 90, 188);
    border-radius: 6px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }

  @media screen and (forced-colors: active) {
    .LQeN7 .VfPpkd-J1Ukfc-LhBDec {
      border-color: CanvasText;
    }
  }

  .LQeN7 .VfPpkd-J1Ukfc-LhBDec::after {
    content: "";
    border: 2px solid rgb(232, 240, 254);
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: calc(100% + 4px);
    width: calc(100% + 4px);
  }

  @media screen and (forced-colors: active) {
    .LQeN7 .VfPpkd-J1Ukfc-LhBDec::after {
      border-color: CanvasText;
    }
  }

  .VfPpkd-xl07Ob-XxIAqe {
    display: none;
    position: absolute;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transform: scale(1);
    transform-origin: top left;
    opacity: 0;
    overflow: auto;
    will-change: transform, opacity;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    transform-origin-left: top left;
    transform-origin-right: top right;
  }

  .VfPpkd-xl07Ob-XxIAqe:focus {
    outline: none;
  }

  .VfPpkd-xl07Ob-XxIAqe-OWXEXe-oYxtQd {
    position: relative;
    overflow: visible;
  }

  .VfPpkd-xl07Ob-XxIAqe-OWXEXe-tsQazb {
    width: 100%;
  }

  .VfPpkd-xl07Ob-XxIAqe {
    max-width: calc(100vw - 32px);
    max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
    max-height: calc(100vh - 32px);
    max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
    z-index: 8;
    transition: opacity 0.03s linear,
      transform 0.12s cubic-bezier(0, 0, 0.2, 1),
      height 0.25s cubic-bezier(0, 0, 0.2, 1);
    background-color: #fff;
    background-color: var(--mdc-theme-surface, #fff);
    color: #000;
    color: var(--mdc-theme-on-surface, #000);
    border-radius: 4px;
    border-radius: var(--mdc-shape-medium, 4px);
  }

  .VfPpkd-StrnGf-rymPhb {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    font-family: var(
      --mdc-typography-subtitle1-font-family,
      var(--mdc-typography-font-family, Roboto, sans-serif)
    );
    font-size: 1rem;
    font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
    line-height: 1.75rem;
    line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
    font-weight: 400;
    font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
    letter-spacing: 0.009375em;
    letter-spacing: var(
      --mdc-typography-subtitle1-letter-spacing,
      0.009375em
    );
    text-decoration: inherit;
    -webkit-text-decoration: var(
      --mdc-typography-subtitle1-text-decoration,
      inherit
    );
    text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
    text-transform: inherit;
    text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
    line-height: 1.5rem;
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  }

  .VfPpkd-StrnGf-rymPhb:focus {
    outline: none;
  }

  .VfPpkd-StrnGf-rymPhb-Zmlebc-LhBDec {
    position: absolute;
  }

  .VfPpkd-StrnGf-rymPhb-ibnC6b {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;
    padding-left: 16px;
    padding-right: 16px;
    height: 48px;
  }

  .VfPpkd-StrnGf-rymPhb-ibnC6b:focus {
    outline: none;
  }

  .VfPpkd-StrnGf-rymPhb-ibnC6b:not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
    ):focus::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-StrnGf-rymPhb-ibnC6b:not(
        .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-gk6SMd
      ):focus::before {
      border-color: CanvasText;
    }
  }

  .VfPpkd-StrnGf-rymPhb-b9t22c {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  :not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-StrnGf-rymPhb-ibnC6b {
    cursor: pointer;
  }

  .VfPpkd-rymPhb-Gtdoyb,
  .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: rgba(0, 0, 0, 0.87);
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  }

  .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-Gtdoyb,
  .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: #6200ee;
    color: var(--mdc-theme-primary, #6200ee);
  }

  .VfPpkd-rymPhb {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    font-family: var(
      --mdc-typography-subtitle1-font-family,
      var(--mdc-typography-font-family, Roboto, sans-serif)
    );
    font-size: 1rem;
    font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
    line-height: 1.75rem;
    line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
    font-weight: 400;
    font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
    letter-spacing: 0.009375em;
    letter-spacing: var(
      --mdc-typography-subtitle1-letter-spacing,
      0.009375em
    );
    text-decoration: inherit;
    -webkit-text-decoration: var(
      --mdc-typography-subtitle1-text-decoration,
      inherit
    );
    text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
    text-transform: inherit;
    text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
    line-height: 1.5rem;
  }

  .VfPpkd-rymPhb-fpDzbe-fmcmS {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    font-family: var(
      --mdc-typography-subtitle1-font-family,
      var(--mdc-typography-font-family, Roboto, sans-serif)
    );
    font-size: 1rem;
    font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
    line-height: 1.75rem;
    line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
    font-weight: 400;
    font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
    letter-spacing: 0.009375em;
    letter-spacing: var(
      --mdc-typography-subtitle1-letter-spacing,
      0.009375em
    );
    text-decoration: inherit;
    -webkit-text-decoration: var(
      --mdc-typography-subtitle1-text-decoration,
      inherit
    );
    text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
    text-transform: inherit;
    text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  }

  .VfPpkd-rymPhb {
    margin: 0;
    padding: 8px 0;
    list-style-type: none;
  }

  .VfPpkd-rymPhb:focus {
    outline: none;
  }

  .VfPpkd-rymPhb-ibnC6b {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;
    align-items: stretch;
    cursor: pointer;
  }

  .VfPpkd-rymPhb-ibnC6b:focus {
    outline: none;
  }

  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
    height: 48px;
  }

  .VfPpkd-rymPhb-ibnC6b:not(
      .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    ):focus::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-rymPhb-ibnC6b:not(
        .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
      ):focus::before {
      border-color: CanvasText;
    }
  }

  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 3px double transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd::before {
      border-color: CanvasText;
    }
  }

  .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:focus::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 3px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:focus::before {
      border-color: CanvasText;
    }
  }

  .VfPpkd-rymPhb-Gtdoyb {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-self: center;
    flex: 1;
    pointer-events: none;
  }

  .VfPpkd-rymPhb-fpDzbe-fmcmS {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .VfPpkd-rymPhb-ibnC6b {
    padding-left: 16px;
    padding-right: 16px;
  }

  :not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-StrnGf-rymPhb-ibnC6b {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
  }

  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::before,
  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
  }

  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
    z-index: var(--mdc-ripple-z-index, 1);
  }

  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::after {
    z-index: 0;
    z-index: var(--mdc-ripple-z-index, 0);
  }

  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::before,
  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::after {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::before,
  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl::after {
    background-color: #000;
    background-color: var(--mdc-ripple-color, #000);
  }

  :not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-StrnGf-rymPhb-ibnC6b:hover
    .VfPpkd-StrnGf-rymPhb-pZXsl::before {
    opacity: 0.04;
    opacity: var(--mdc-ripple-hover-opacity, 0.04);
  }

  :not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):focus
    .VfPpkd-StrnGf-rymPhb-pZXsl::before {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-focus-opacity, 0.12);
  }

  :not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d)
    .VfPpkd-StrnGf-rymPhb-pZXsl::after {
    transition: opacity 0.15s linear;
  }

  :not(
      .VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-StrnGf-rymPhb-ibnC6b:not(.VfPpkd-ksKsZd-mWPk3d):active
    .VfPpkd-StrnGf-rymPhb-pZXsl::after {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-press-opacity, 0.12);
  }

  :not(.VfPpkd-StrnGf-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-StrnGf-rymPhb-ibnC6b
    .VfPpkd-StrnGf-rymPhb-pZXsl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::before,
  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: "";
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1;
    z-index: var(--mdc-ripple-z-index, 1);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::after {
    z-index: 0;
    z-index: var(--mdc-ripple-z-index, 0);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::before,
  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::after {
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::before,
  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl::after {
    background-color: #000;
    background-color: var(--mdc-ripple-color, #000);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:hover
    .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0.04;
    opacity: var(--mdc-ripple-hover-opacity, 0.04);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:not(
      .VfPpkd-ksKsZd-mWPk3d
    ):focus
    .VfPpkd-rymPhb-pZXsl::before {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-focus-opacity, 0.12);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:not(
      .VfPpkd-ksKsZd-mWPk3d
    )
    .VfPpkd-rymPhb-pZXsl::after {
    transition: opacity 0.15s linear;
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b:not(
      .VfPpkd-ksKsZd-mWPk3d
    ):active
    .VfPpkd-rymPhb-pZXsl::after {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-press-opacity, 0.12);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0.08;
    opacity: var(--mdc-ripple-selected-opacity, 0.08);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::before,
  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::after {
    background-color: #6200ee;
    background-color: var(
      --mdc-ripple-color,
      var(--mdc-theme-primary, #6200ee)
    );
  }

  :not(
      .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
    .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0.12;
    opacity: var(--mdc-ripple-hover-opacity, 0.12);
  }

  :not(
      .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):focus
    .VfPpkd-rymPhb-pZXsl::before {
    transition-duration: 75ms;
    opacity: 0.2;
    opacity: var(--mdc-ripple-focus-opacity, 0.2);
  }

  :not(
      .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d)
    .VfPpkd-rymPhb-pZXsl::after {
    transition: opacity 0.15s linear;
  }

  :not(
      .VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me
    ).VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(.VfPpkd-ksKsZd-mWPk3d):active
    .VfPpkd-rymPhb-pZXsl::after {
    transition-duration: 75ms;
    opacity: 0.2;
    opacity: var(--mdc-ripple-press-opacity, 0.2);
  }

  :not(.VfPpkd-rymPhb-ibnC6b-OWXEXe-OWB6Me).VfPpkd-rymPhb-ibnC6b
    .VfPpkd-rymPhb-pZXsl {
    position: relative;
    outline: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .VfPpkd-xl07Ob {
    min-width: 112px;
    min-width: var(--mdc-menu-min-width, 112px);
  }

  .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb {
    color: rgba(0, 0, 0, 0.87);
  }

  .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb,
  .VfPpkd-xl07Ob .VfPpkd-rymPhb {
    position: relative;
  }

  .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb .VfPpkd-BFbNVe-bF1uUb,
  .VfPpkd-xl07Ob .VfPpkd-rymPhb .VfPpkd-BFbNVe-bF1uUb {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb::before,
  .VfPpkd-xl07Ob .VfPpkd-rymPhb::before {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    content: "";
    pointer-events: none;
  }

  @media screen and (forced-colors: active) {
    .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb::before,
    .VfPpkd-xl07Ob .VfPpkd-rymPhb::before {
      border-color: CanvasText;
    }
  }

  .VfPpkd-xl07Ob .VfPpkd-StrnGf-rymPhb-ibnC6b {
    user-select: none;
  }

  .P2Hi5d,
  .DMZ54e {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.00625em;
    font-weight: 400;
    color: #000;
    color: var(--mdc-theme-on-surface, #000);
  }

  .r6B9Fd {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.00625em;
    font-weight: 400;
  }

  .r6B9Fd .VfPpkd-rymPhb-Gtdoyb,
  .r6B9Fd .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: rgb(60, 64, 67);
  }

  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-Gtdoyb,
  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: rgb(60, 64, 67);
  }

  .r6B9Fd .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0;
  }

  .r6B9Fd .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd {
    background-color: rgb(232, 240, 254);
  }

  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::before,
  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::after {
    background-color: rgb(26, 115, 232);
    background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
  }

  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
    .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0.04;
    opacity: var(--mdc-ripple-hover-opacity, 0.04);
  }

  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(
      .VfPpkd-ksKsZd-mWPk3d
    ):focus
    .VfPpkd-rymPhb-pZXsl::before {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-focus-opacity, 0.12);
  }

  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(
      .VfPpkd-ksKsZd-mWPk3d
    )
    .VfPpkd-rymPhb-pZXsl::after {
    transition: opacity 0.15s linear;
  }

  .r6B9Fd
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(
      .VfPpkd-ksKsZd-mWPk3d
    ):active
    .VfPpkd-rymPhb-pZXsl::after {
    transition-duration: 75ms;
    opacity: 0.1;
    opacity: var(--mdc-ripple-press-opacity, 0.1);
  }

  .HiC7Nc.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
    height: 56px;
  }

  .MCs1Pd {
    padding-left: 24px;
    padding-right: 24px;
  }

  .q6oraf {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  }

  .q6oraf .VfPpkd-StrnGf-rymPhb {
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.00625em;
    font-weight: 400;
    color: #000;
    color: var(--mdc-theme-on-surface, #000);
  }

  .XjS9D {
    display: inline-flex;
  }

  .XjS9D .VfPpkd-J1Ukfc-LhBDec {
    border-radius: 22px;
  }

  .XjS9D .VfPpkd-J1Ukfc-LhBDec::after {
    border-radius: 24px;
  }

  .XjS9D .q6oraf .DMZ54e,
  .XjS9D .BqKGqe {
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    font-weight: var(--c-afwt, 500);
    letter-spacing: 0rem;
    line-height: 1.42857143;
  }

  .BqKGqe,
  .BqKGqe .VfPpkd-Jh9lGc {
    border-radius: 20px;
  }

  .XjS9D .VfPpkd-LgbsSe {
    height: 40px;
  }

  @media (orientation: landscape) {
    .XjS9D .VfPpkd-LgbsSe {
      height: 40px;
    }
  }

  .Jskylb:not(:disabled) {
    background: #0b57d0;
    color: #fff;
  }

  .eR0mzb:not(:disabled) {
    color: #0b57d0;
    outline: #747775;
  }

  .Jskylb.Jskylb.Jskylb:not(:disabled) {
    background-color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .Jskylb.Jskylb.Jskylb:not(:disabled) {
    color: var(--gm3-sys-color-on-primary, #fff);
  }

  .Jskylb.Jskylb.Jskylb:not(:disabled):hover {
    color: var(--gm3-sys-color-on-primary, #fff);
  }

  .Jskylb.Jskylb.Jskylb:not(:disabled):not(.VfPpkd-ksKsZd-mWPk3d):focus {
    color: var(--gm3-sys-color-on-primary, #fff);
  }

  .Jskylb.Jskylb.Jskylb:not(:disabled):not(:disabled):active {
    color: var(--gm3-sys-color-on-primary, #fff);
  }

  .eR0mzb.eR0mzb.eR0mzb:not(:disabled) {
    color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .eR0mzb.eR0mzb.eR0mzb:not(:disabled):hover {
    color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .eR0mzb.eR0mzb.eR0mzb:not(:disabled):not(.VfPpkd-ksKsZd-mWPk3d):focus {
    color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .eR0mzb.eR0mzb.eR0mzb .VfPpkd-Jh9lGc::before {
    background-color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .eR0mzb.eR0mzb.eR0mzb .VfPpkd-Jh9lGc::after {
    background-color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .eR0mzb.VfPpkd-LgbsSe {
    min-width: 0;
  }

  .eR0mzb.VfPpkd-LgbsSe {
    padding-left: 16px;
    padding-right: 16px;
  }

  .rFrNMe {
    -webkit-user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    outline: none;
    padding-bottom: 8px;
    width: 200px;
  }

  .aCsJod {
    height: 40px;
    position: relative;
    vertical-align: top;
  }

  .aXBtI {
    display: flex;
    position: relative;
    top: 14px;
  }

  .Xb9hP {
    display: flex;
    box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0%;
    position: relative;
  }

  .whsOnd {
    box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: transparent;
    border: none;
    display: block;
    font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    height: 24px;
    line-height: 24px;
    margin: 0;
    min-width: 0%;
    outline: none;
    padding: 0;
    z-index: 0;
  }

  .whsOnd:invalid {
    box-shadow: none;
  }

  .i9lrp {
    background-color: rgba(0, 0, 0, 0.12);
    bottom: -2px;
    height: 1px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
  }

  .i9lrp:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: -2px;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    pointer-events: none;
  }

  .OabDMe {
    transform: scaleX(0);
    background-color: #4285f4;
    bottom: -2px;
    height: 2px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
  }

  .OabDMe.Y2Zypf {
    animation: quantumWizPaperInputRemoveUnderline 0.3s
      cubic-bezier(0.4, 0, 0.2, 1);
  }

  .rFrNMe.sdJrJc > .aCsJod {
    padding-top: 24px;
  }

  .AxOyFc {
    transform-origin: bottom left;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: color, bottom, transform;
    -webkit-transition-property: color, bottom, transform, -webkit-transform;
    transition-property: color, bottom, transform, -webkit-transform;
    color: rgba(0, 0, 0, 0.38);
    font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    font-size: 16px;
    pointer-events: none;
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
  }

  .whsOnd:not([disabled]):focus ~ .AxOyFc {
    transform: scale(0.75) translateY(-39px);
  }

  .whsOnd:not([disabled]):focus ~ .AxOyFc {
    color: #3367d6;
  }

  .rFrNMe.Tyc9J {
    padding-bottom: 4px;
  }

  .dEOOab,
  .ovnfwe:not(:empty) {
    -webkit-tap-highlight-color: transparent;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    font: 400 12px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
    min-height: 16px;
    padding-top: 8px;
  }

  .LXRPh {
    display: flex;
  }

  .ovnfwe {
    pointer-events: none;
  }

  .dEOOab {
    color: #d50000;
  }

  .ovnfwe {
    opacity: 0.3;
  }

  .rFrNMe:not(.k0tWj) .ovnfwe:not(:empty) + .dEOOab {
    display: none;
  }

  .X3mtXb {
    box-sizing: content-box;
  }

  .X3mtXb {
    display: block;
    padding: 16px 0 0;
    width: 100%;
  }

  :first-child > .X3mtXb {
    padding: 8px 0 0;
  }

  .AFTWye .X3mtXb .oJeWuf {
    height: 56px;
    padding-top: 0;
  }

  .X3mtXb .Wic03c {
    align-items: center;
    position: static;
    top: 0;
  }

  .X3mtXb .snByac {
    background: white;
    background: var(--gm3-sys-color-background, white);
    color: #444746;
    color: var(--gm3-sys-color-on-surface-variant, #444746);
    bottom: 17px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    left: 8px;
    max-width: calc(100% - 16px);
    overflow: hidden;
    padding: 0 8px;
    text-overflow: ellipsis;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
      opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
    width: auto;
    z-index: 1;
  }

  .X3mtXb .ovnfwe {
    pointer-events: auto;
  }

  .X3mtXb .Is7Fhb,
  .X3mtXb .RxsGPe {
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
    line-height: 1.33333333;
    opacity: 1;
    padding-top: 4px;
  }

  .AFTWye .Is7Fhb {
    color: var(--gm3-sys-color-on-surface-variant, #444746);
    margin-left: 16px;
  }

  .X3mtXb .RxsGPe {
    color: #b3261e;
    color: var(--gm3-sys-color-error, #b3261e);
  }

  .X3mtXb .Is7Fhb:empty,
  .X3mtXb .RxsGPe:empty {
    display: none;
  }

  .X3mtXb .zHQkBf {
    border-radius: 4px;
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-size: 16px;
    height: 28px;
    margin: 1px 1px 0 1px;
    padding: 13px 15px;
    text-align: left;
    width: 100%;
    z-index: 1;
  }

  .UOsO2 .Wic03c,
  .UOsO2 .zHQkBf {
    direction: ltr;
    text-align: left;
  }

  .UOsO2 .Wic03c .snByac {
    direction: ltr;
  }

  .X3mtXb .AxOyFc {
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
  }

  .X3mtXb .whsOnd:not([disabled]):focus ~ .AxOyFc {
    color: #0b57d0;
    color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .X3mtXb .mIZh1c {
    border: 1px solid;
    border-color: #747775;
    border-color: var(--gm3-sys-color-outline, #747775);
    border-radius: 4px;
    bottom: 0;
    box-sizing: border-box;
  }

  .X3mtXb .cXrdqd {
    border-radius: 4px;
    bottom: 0;
    opacity: 0;
    transform: none;
    transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    width: calc(100% - 4px);
  }

  .X3mtXb .mIZh1c,
  .X3mtXb .cXrdqd {
    background-color: transparent;
  }

  .X3mtXb .mIZh1c {
    height: 100%;
  }

  .X3mtXb .cXrdqd {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  }

  .Em2Ord {
    margin: 16px 0;
    outline: none;
  }

  .Em2Ord:first-child {
    margin-top: 0;
  }

  .Em2Ord:last-child {
    margin-bottom: 0;
  }

  .vYeFie:empty {
    display: none;
  }

  .vYeFie:empty + .yTaH4c {
    margin-top: 0;
  }

  .yTaH4c {
    margin-bottom: 16px;
    margin-top: 10px;
  }

  .yTaH4c > [jsslot] > :first-child:not(.PsAlOe) {
    margin-top: 0;
    padding-top: 0;
  }

  .yTaH4c > [jsslot] > :last-child:not(.PsAlOe) {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .jR8x9d {
    color-scheme: light;
  }

  .jR8x9d,
  .jR8x9d input,
  .jR8x9d button {
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
  }

  .jR8x9d {
    background-color: white;
    background-color: var(--gm3-sys-color-background, white);
    direction: ltr;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.42857143;
    margin: 0;
    padding: 0;
  }

  .S7xv8 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: white;
    background: var(--gm3-sys-color-background, white);
    justify-content: space-between;
    padding: 0;
  }

  @media (min-width: 600px) {
    .S7xv8 {
      background: #f0f4f9;
      background: var(--gm3-sys-color-surface-container, #f0f4f9);
      justify-content: center;
      padding: 48px 0;
    }
  }

  @media (min-width: 600px) and (orientation: landscape) {
    .S7xv8 {
      background: white;
      background: var(--gm3-sys-color-background, white);
      justify-content: space-between;
      padding: 0;
    }
  }

  @media (min-width: 960px) and (orientation: landscape) {
    .S7xv8 {
      background: #f0f4f9;
      background: var(--gm3-sys-color-surface-container, #f0f4f9);
      justify-content: center;
      padding: 48px 0;
    }
  }

  .fAlnEc a:not(.TrZEUc):not(.WpHeLc) {
    text-decoration: none;
  }

  .fAlnEc a:not(.TrZEUc):not(.WpHeLc),
  .fAlnEc button:not(.TrZEUc) {
    color: #0b57d0;
    color: var(--gm3-sys-color-primary, #0b57d0);
    border-radius: 8px;
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.015625rem;
    outline: 0;
    z-index: 1;
  }

  .fAlnEc button:not(.TrZEUc) {
    background-color: transparent;
    cursor: pointer;
    font-size: inherit;
    padding: 0;
    text-align: left;
  }

  .fAlnEc button:not(.TrZEUc) {
    border: 0;
  }

  .fAlnEc a:not(.TrZEUc):not(.WpHeLc),
  .fAlnEc button:not(.TrZEUc) {
    position: relative;
  }

  .fAlnEc a:not(.TrZEUc):not(.WpHeLc)::before,
  .fAlnEc button:not(.TrZEUc)::before {
    background-color: #0b57d0;
    background-color: var(--gm3-sys-color-primary, #0b57d0);
    border-radius: 8px;
    bottom: -1px;
    content: "";
    left: -5px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -5px;
    top: -1px;
    z-index: -1;
  }

  .fAlnEc a:not(.TrZEUc):not(.WpHeLc):hover::before,
  .fAlnEc button:not(.TrZEUc):hover::before {
    opacity: 0.08;
  }

  .fAlnEc a:not(.TrZEUc):not(.WpHeLc):focus::before,
  .fAlnEc button:not(.TrZEUc):focus::before,
  .fAlnEc a:not(.TrZEUc):not(.WpHeLc):active::before,
  .fAlnEc button:not(.TrZEUc):active::before {
    opacity: 0.1;
  }

  .O1htCb-H9tDt {
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }

  .VfPpkd-O1htCb {
    display: inline-flex;
    position: relative;
  }

  .VfPpkd-t08AT-Bz112c {
    display: inline-flex;
    position: relative;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    pointer-events: none;
  }

  .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-auswjd,
  .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-mt1Mkb {
    position: absolute;
    top: 0;
    left: 0;
  }

  .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-Bd00G {
    width: 41.6666666667%;
    height: 20.8333333333%;
  }

  .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-mt1Mkb {
    opacity: 1;
    transition: opacity 75ms linear 75ms;
  }

  .VfPpkd-t08AT-Bz112c .VfPpkd-t08AT-Bz112c-auswjd {
    opacity: 0;
    transition: opacity 75ms linear;
  }

  .VfPpkd-TkwUic {
    min-width: 0;
    flex: 1 1 auto;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    outline: none;
    cursor: pointer;
  }

  .VfPpkd-aPP78e {
    position: absolute;
    top: 50%;
    height: 48px;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }

  .VfPpkd-uusGie-fmcmS-haAclf {
    display: flex;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: none;
    box-sizing: border-box;
    width: auto;
    min-width: 0;
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent;
    color: inherit;
  }

  .VfPpkd-uusGie-fmcmS {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto, sans-serif;
    font-family: var(
      --mdc-typography-subtitle1-font-family,
      var(--mdc-typography-font-family, Roboto, sans-serif)
    );
    font-size: 1rem;
    font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
    line-height: 1.75rem;
    line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
    font-weight: 400;
    font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
    letter-spacing: 0.009375em;
    letter-spacing: var(
      --mdc-typography-subtitle1-letter-spacing,
      0.009375em
    );
    text-decoration: inherit;
    -webkit-text-decoration: var(
      --mdc-typography-subtitle1-text-decoration,
      inherit
    );
    text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
    text-transform: inherit;
    text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    width: 100%;
    text-align: left;
  }

  @media (-ms-high-contrast: active), screen and (forced-colors: active) {
    .VfPpkd-YPmvEd::before {
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 1px solid transparent;
      border-radius: inherit;
      content: "";
      pointer-events: none;
    }
  }

  @media screen and (-ms-high-contrast: active) and (forced-colors: active),
    screen and (forced-colors: active) and (forced-colors: active) {
    .VfPpkd-YPmvEd::before {
      border-color: CanvasText;
    }
  }

  .VfPpkd-OkbHre {
    padding-left: 16px;
    padding-right: 16px;
  }

  .VfPpkd-aJasdd-RWgCYc-wQNmvb.VfPpkd-rymPhb-ibnC6b-OWXEXe-SfQLQb-Woal0c-RWgCYc {
    height: 48px;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc {
    border: none;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic {
    display: flex;
    align-items: baseline;
    overflow: visible;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-uusGie-fmcmS-haAclf {
    display: flex;
    border: none;
    z-index: 1;
    background-color: transparent;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-xl07Ob-XxIAqe {
    margin-bottom: 8px;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc.VfPpkd-O1htCb-OWXEXe-di8rgd-V67aGc
    .VfPpkd-xl07Ob-XxIAqe {
    margin-bottom: 0;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic {
    height: 56px;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
    border-top-left-radius: 4px;
    border-top-left-radius: var(--mdc-shape-small, 4px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-left-radius: var(--mdc-shape-small, 4px);
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    border-top-right-radius: var(--mdc-shape-small, 4px);
    border-bottom-right-radius: 4px;
    border-bottom-right-radius: var(--mdc-shape-small, 4px);
    border-bottom-left-radius: 0;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
    .VfPpkd-TkwUic {
    background-color: transparent;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
    .VfPpkd-NSFCdd-Brv4Fb,
  .VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me)
    .VfPpkd-NSFCdd-MpmGFe {
    border-color: rgba(0, 0, 0, 0.38);
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(
      .VfPpkd-O1htCb-OWXEXe-XpnDCe
    )
    .VfPpkd-TkwUic:hover
    .VfPpkd-NSFCdd-i5vt6e
    .VfPpkd-NSFCdd-Brv4Fb,
  .VfPpkd-O1htCb-OWXEXe-INsAgc:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(
      .VfPpkd-O1htCb-OWXEXe-XpnDCe
    )
    .VfPpkd-TkwUic:hover
    .VfPpkd-NSFCdd-i5vt6e
    .VfPpkd-NSFCdd-MpmGFe {
    border-color: rgba(0, 0, 0, 0.87);
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic .VfPpkd-uusGie-fmcmS::before {
    content: "​";
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic .VfPpkd-uusGie-fmcmS-haAclf {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  .VfPpkd-O1htCb-OWXEXe-INsAgc .VfPpkd-TkwUic::before {
    display: none;
  }

  .VfPpkd-TkwUic {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
  }

  .VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
    color: rgba(0, 0, 0, 0.87);
  }

  .VfPpkd-O1htCb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
    fill: rgba(0, 0, 0, 0.54);
  }

  .VfPpkd-O1htCb .VfPpkd-TkwUic {
    padding-left: 16px;
    padding-right: 0;
  }

  .VfPpkd-O1htCb .VfPpkd-t08AT-Bz112c {
    width: 24px;
    height: 24px;
  }

  .VfPpkd-t08AT-Bz112c {
    margin-left: 12px;
    margin-right: 12px;
  }

  .VfPpkd-TkwUic {
    width: 200px;
  }

  .VfPpkd-uusGie-fmcmS-haAclf {
    height: 28px;
  }

  .s8kOBc {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    font-family: Roboto, Arial, sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.00625em;
    font-weight: 400;
  }

  .s8kOBc .VfPpkd-rymPhb-Gtdoyb,
  .s8kOBc .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: rgb(60, 64, 67);
  }

  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-Gtdoyb,
  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: rgb(60, 64, 67);
  }

  .s8kOBc .VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0;
  }

  .s8kOBc .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd {
    background-color: rgb(232, 240, 254);
  }

  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::before,
  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd
    .VfPpkd-rymPhb-pZXsl::after {
    background-color: rgb(26, 115, 232);
    background-color: var(--mdc-ripple-color, rgb(26, 115, 232));
  }

  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:hover
    .VfPpkd-rymPhb-pZXsl::before {
    opacity: 0.04;
    opacity: var(--mdc-ripple-hover-opacity, 0.04);
  }

  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(
      .VfPpkd-ksKsZd-mWPk3d
    ):focus
    .VfPpkd-rymPhb-pZXsl::before {
    transition-duration: 75ms;
    opacity: 0.12;
    opacity: var(--mdc-ripple-focus-opacity, 0.12);
  }

  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(
      .VfPpkd-ksKsZd-mWPk3d
    )
    .VfPpkd-rymPhb-pZXsl::after {
    transition: opacity 0.15s linear;
  }

  .s8kOBc
    .VfPpkd-rymPhb-ibnC6b.VfPpkd-rymPhb-ibnC6b-OWXEXe-gk6SMd:not(
      .VfPpkd-ksKsZd-mWPk3d
    ):active
    .VfPpkd-rymPhb-pZXsl::after {
    transition-duration: 75ms;
    opacity: 0.1;
    opacity: var(--mdc-ripple-press-opacity, 0.1);
  }

  .s8kOBc .VfPpkd-rymPhb-fpDzbe-fmcmS {
    letter-spacing: 0.00625em;
  }

  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb,
  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
    border-color: rgb(128, 134, 139);
  }

  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
    .VfPpkd-TkwUic:hover
    .VfPpkd-NSFCdd-i5vt6e
    .VfPpkd-NSFCdd-Brv4Fb,
  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(.VfPpkd-O1htCb-OWXEXe-XpnDCe)
    .VfPpkd-TkwUic:hover
    .VfPpkd-NSFCdd-i5vt6e
    .VfPpkd-NSFCdd-MpmGFe {
    border-color: rgb(32, 33, 36);
  }

  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
    color: rgb(60, 64, 67);
  }

  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
    fill: rgb(95, 99, 104);
  }

  .ReCbLb:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(
      .VfPpkd-O1htCb-OWXEXe-XpnDCe
    ):hover
    .VfPpkd-t08AT-Bz112c {
    fill: rgb(32, 33, 36);
  }

  .VfPpkd-BFbNVe-bF1uUb {
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
    opacity: 0;
    opacity: var(--mdc-elevation-overlay-opacity, 0);
    transition: opacity 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #fff;
    background-color: var(--mdc-elevation-overlay-color, #fff);
  }

  .VfPpkd-NSFCdd-i5vt6e {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;
  }

  .VfPpkd-NSFCdd-Brv4Fb,
  .VfPpkd-NSFCdd-MpmGFe {
    box-sizing: border-box;
    height: 100%;
    pointer-events: none;
  }

  .VfPpkd-NSFCdd-MpmGFe {
    flex-grow: 1;
  }

  .VfPpkd-NSFCdd-Brv4Fb,
  .VfPpkd-NSFCdd-MpmGFe {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }

  .VfPpkd-NSFCdd-Brv4Fb {
    border-left: 1px solid;
    border-right: none;
    width: 12px;
  }

  .VfPpkd-NSFCdd-MpmGFe {
    border-left: none;
    border-right: 1px solid;
  }

  .NZp2ef {
    background-color: #e8eaed;
  }

  .sZwd7c {
    height: 4px;
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    transition: opacity 250ms linear;
    width: 100%;
  }

  .w2zcLc {
    position: absolute;
  }

  .xcNBHc,
  .MyvhI,
  .l3q5xe {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .w2zcLc {
    transform-origin: top left;
    transition: transform 250ms ease;
    -webkit-transition: transform 250ms ease, -webkit-transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
  }

  .MyvhI {
    transform-origin: top left;
    transition: transform 250ms ease;
    -webkit-transition: transform 250ms ease, -webkit-transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
    animation: none;
  }

  .l3q5xe {
    animation: none;
  }

  .w2zcLc {
    background-color: #e6e6e6;
    height: 100%;
    transform-origin: top left;
    transition: transform 250ms ease;
    -webkit-transition: transform 250ms ease, -webkit-transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
    width: 100%;
  }

  .TKVRUb {
    transform: scaleX(0);
  }

  .sUoeld {
    visibility: hidden;
  }

  .l3q5xe {
    background-color: #000;
    display: inline-block;
  }

  .xcNBHc {
    background-size: 10px 4px;
    background-repeat: repeat-x;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27%23e6e6e6%27%2F%3E%3C%2Fsvg%3E");
    visibility: hidden;
  }

  .sZwd7c.B6Vhqe .MyvhI {
    transition: none;
  }

  .sZwd7c.B6Vhqe .TKVRUb {
    animation: primary-indeterminate-translate 2s infinite linear;
  }

  .sZwd7c.B6Vhqe .TKVRUb > .l3q5xe {
    animation: primary-indeterminate-scale 2s infinite linear;
  }

  .sZwd7c.B6Vhqe .sUoeld {
    animation: auxiliary-indeterminate-translate 2s infinite linear;
    visibility: visible;
  }

  .sZwd7c.B6Vhqe .sUoeld > .l3q5xe {
    animation: auxiliary-indeterminate-scale 2s infinite linear;
  }

  .sZwd7c.qdulke {
    opacity: 0;
  }

  .sZwd7c.jK7moc .sUoeld,
  .sZwd7c.jK7moc .TKVRUb,
  .sZwd7c.jK7moc .sUoeld > .l3q5xe,
  .sZwd7c.jK7moc .TKVRUb > .l3q5xe {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
  }

  .Wf6lSd {
    display: flex;
    justify-content: flex-start;
    height: 48px;
    height: var(--c-brsz, 48px);
  }

  .FZfKCe {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .HwzH1e {
    display: flex;
    list-style: none;
    margin: 0 -12px;
    padding: 0;
  }

  .qKvP1b {
    display: flex;
    margin: 16px 4px;
  }

  .qKvP1b:first-child {
    margin-left: 0;
  }

  .qKvP1b:last-child {
    margin-right: 0;
  }

  .AVAq4d {
    align-content: center;
    border-radius: 8px;
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
    display: flex;
    flex-wrap: wrap;
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
    line-height: 1.33333333;
    outline: none;
    padding: 8px 12px;
    text-decoration: none;
  }

  .AVAq4d {
    position: relative;
  }

  .AVAq4d::before {
    background: #444746;
    background: var(--gm3-sys-color-on-surface-variant, #444746);
    content: "";
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .AVAq4d:hover::before {
    opacity: 0.08;
  }

  .AVAq4d:focus::before {
    opacity: 0.1;
  }

  .AVAq4d:active::before {
    opacity: 0.1;
  }

  .AVAq4d::before {
    border-radius: 8px;
    inset: 0;
  }

  .eXa0v {
    margin: 16px 0;
    margin-right: 12px;
  }

  .dEoyBf {
    margin-left: -16px;
  }

  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb,
  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
    border-color: transparent;
  }

  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-Brv4Fb,
  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-NSFCdd-MpmGFe {
    border-width: 0;
  }

  .dEoyBf .VfPpkd-OkbHre .VfPpkd-rymPhb-Gtdoyb,
  .dEoyBf .VfPpkd-OkbHre .VfPpkd-rymPhb-fpDzbe-fmcmS {
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
  }

  .dEoyBf .VfPpkd-OkbHre .VfPpkd-rymPhb-fpDzbe-fmcmS {
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    line-height: 1.33333333;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
  }

  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-t08AT-Bz112c {
    fill: var(--gm3-sys-color-on-surface-variant, #444746);
  }

  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me):not(
      .VfPpkd-O1htCb-OWXEXe-XpnDCe
    ):hover
    .VfPpkd-t08AT-Bz112c {
    fill: var(--gm3-sys-color-on-surface-variant, #444746);
  }

  .dEoyBf:not(.VfPpkd-O1htCb-OWXEXe-OWB6Me) .VfPpkd-uusGie-fmcmS {
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
  }

  .dEoyBf .VfPpkd-uusGie-fmcmS {
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    line-height: 1.33333333;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.00625rem;
  }

  .dEoyBf .VfPpkd-TkwUic {
    height: 32px;
  }

  .dEoyBf .VfPpkd-t08AT-Bz112c {
    width: 20px;
    height: 20px;
  }

  .dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-Brv4Fb {
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 8px;
  }

  .dEoyBf .VfPpkd-NSFCdd-i5vt6e .VfPpkd-NSFCdd-MpmGFe {
    border-top-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0;
  }

  .dEoyBf {
    position: relative;
  }

  .dEoyBf::before {
    background: #444746;
    background: var(--gm3-sys-color-on-surface-variant, #444746);
    content: "";
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .dEoyBf:hover::before {
    opacity: 0.08;
  }

  .dEoyBf:focus::before {
    opacity: 0.1;
  }

  .dEoyBf:active::before {
    opacity: 0.1;
  }

  .dEoyBf::before {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  .JYXaTc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: -6px;
    margin-left: -16px;
    margin-top: 32px;
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .JYXaTc {
      width: 100%;
    }
  }

  .O1Slxf {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    width: 100%;
  }

  .TNTaPb,
  .FO2vFd {
    display: flex;
    flex-grow: 1;
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .JYXaTc:not(.NNItQ) .TNTaPb,
    .NQ5OL .JYXaTc:not(.NNItQ) .FO2vFd {
      flex-grow: unset;
    }
  }

  .FO2vFd {
    justify-content: flex-start;
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .FO2vFd {
      margin-left: 0;
      margin-right: -16px;
    }
  }

  .TNTaPb {
    justify-content: flex-end;
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .TNTaPb {
      margin-left: 40px;
      margin-right: 0;
    }
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .TNTaPb:empty {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .JYXaTc:not(.NNItQ) .FO2vFd .J7pUA {
    text-align: left;
  }

  .n3Clv .q6oraf {
    border-radius: 16px;
  }

  .JYXaTc .KMdFve {
    --mdc-ripple-color: var(--gm3-sys-color-on-surface, #1f1f1f);
    background-color: var(--gm3-sys-color-surface-container, #f0f4f9);
  }

  .JYXaTc .KMdFve .VfPpkd-BFbNVe-bF1uUb {
    background-color: var(--gm3-sys-color-surface-tint, #6991d6);
  }

  .gNVsKb {
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
  }

  .Ih3FE {
    height: 4px;
    overflow: hidden;
  }

  .Ih3FE .l3q5xe {
    background-color: #0b57d0;
    background-color: var(--gm3-sys-color-primary, #0b57d0);
  }

  .Ih3FE .w2zcLc {
    background-color: #f0f4f9;
    background-color: var(--gm3-sys-color-surface-container, #f0f4f9);
  }

  :root {
    --wf-gutw-hlf: calc((var(--c-gutw, 48px)) / 2);
    --wf-gutw-hlf-bp2: calc((var(--c-gutw, 76px)) / 2);
    --wf-brsz: calc(var(--c-brsz, 48px) + 24px);
    --wf-ps-t: calc(var(--c-ps-t, 24px) + var(--wf-brsz, 72px));
    --wf-ps-t-bp2: calc(var(--c-ps-t, 24px) + var(--wf-brsz, 72px));
    --wf-ps-t-bp5: calc(var(--c-ps-t, 36px) + var(--wf-brsz, 72px));
    --wf-ps-t-bp3-l: calc(var(--c-ps-t, 36px) + var(--wf-brsz, 72px));
  }

  .TcuCfd {
    background-color: white !important;
    background-color: var(--gm3-sys-color-background, white);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 24px;
    position: relative;
    padding-top: 96px;
    padding-top: var(--wf-ps-t, 96px);
  }

  .TcuCfd,
  .wmGw4 {
    margin: 0 auto;
    padding-left: 24px;
    padding-left: var(--c-ps-s, 24px);
    padding-right: 24px;
    padding-right: var(--c-ps-e, 24px);
    width: 100%;
  }

  @media (min-width: 600px) {
    .TcuCfd {
      border-radius: 28px;
      min-height: 528px;
      padding-left: 24px;
      padding-left: var(--c-ps-s, 24px);
      padding-right: 24px;
      padding-right: var(--c-ps-e, 24px);
      width: 480px;
    }

    .wmGw4 {
      padding: 0 16px;
      width: 480px;
    }
  }

  @media (min-width: 600px) and (orientation: landscape) {
    .TcuCfd,
    .wmGw4 {
      padding-left: 24px;
      padding-left: var(--c-ps-s, 24px);
      padding-right: 24px;
      padding-right: var(--c-ps-e, 24px);
      width: 100%;
    }

    .TcuCfd {
      border-radius: 28px;
      min-height: unset;
    }
  }

  @media (min-width: 840px) {
    .TcuCfd {
      padding-bottom: 24px;
      padding-left: 24px;
      padding-left: var(--c-ps-s, 24px);
      padding-right: 24px;
      padding-right: var(--c-ps-e, 24px);
      padding-top: 96px;
      padding-top: var(--wf-ps-t-bp2, 96px);
      width: 480px;
    }

    .wmGw4 {
      width: 480px;
    }
  }

  @media (min-width: 840px) and (orientation: landscape) {
    .TcuCfd,
    .wmGw4 {
      padding-left: 32px;
      padding-left: var(--c-ps-s, 32px);
      padding-right: 32px;
      padding-right: var(--c-ps-e, 32px);
      width: 100%;
    }
  }

  @media (min-width: 960px) {
    .TcuCfd {
      padding-left: 24px;
      padding-left: var(--c-ps-s, 24px);
      padding-right: 24px;
      padding-right: var(--c-ps-e, 24px);
      width: 480px;
    }

    .wmGw4 {
      width: 480px;
    }
  }

  @media (min-width: 960px) and (orientation: landscape) {
    .TcuCfd {
      border-radius: 28px;
      min-height: 384px;
      padding-left: 36px;
      padding-left: var(--c-ps-s, 36px);
      padding-right: 36px;
      padding-right: var(--c-ps-e, 36px);
      padding-bottom: 36px;
      padding-bottom: var(--c-ps-t, 36px);
      padding-top: 108px;
      padding-top: var(--wf-ps-t-bp3-l, 108px);
      width: 840px;
    }

    .wmGw4 {
      padding: 0 16px;
      width: 840px;
    }
  }

  @media (min-width: 1240px) {
    .TcuCfd {
      padding-left: 24px;
      padding-left: var(--c-ps-s, 24px);
      padding-right: 24px;
      padding-right: var(--c-ps-e, 24px);
      width: 480px;
    }

    .wmGw4 {
      width: 480px;
    }
  }

  @media (min-width: 1240px) and (orientation: landscape) {
    .TcuCfd {
      margin-left: 200px;
      margin-right: 200px;
      padding-left: 36px;
      padding-left: var(--c-ps-s, 36px);
      padding-right: 36px;
      padding-right: var(--c-ps-e, 36px);
      width: auto;
    }

    .wmGw4 {
      width: auto;
      margin-left: 200px;
      margin-right: 200px;
    }
  }

  @media (min-width: 1600px) {
    .TcuCfd {
      min-height: 384px;
      padding-bottom: 36px;
      padding-left: 36px;
      padding-left: var(--c-ps-s, 36px);
      padding-right: 36px;
      padding-right: var(--c-ps-e, 36px);
      padding-top: 108px;
      padding-top: var(--wf-ps-t-bp5, 108px);
      width: 1040px;
    }

    .wmGw4 {
      width: 1040px;
    }
  }

  @media (min-width: 1600px) and (orientation: landscape) {
    .TcuCfd {
      margin-left: auto;
      margin-right: auto;
      padding-left: 36px;
      padding-left: var(--c-ps-s, 36px);
      padding-right: 36px;
      padding-right: var(--c-ps-e, 36px);
      width: 1040px;
    }

    .wmGw4 {
      margin-left: auto;
      margin-right: auto;
      width: 1040px;
    }
  }

  .Ih3FE {
    left: 24px;
    left: var(--c-ps-s, 24px);
    position: absolute;
    right: 24px;
    right: var(--c-ps-e, 24px);
    top: 0;
    z-index: 5;
  }

  @media (min-width: 600px) {
    .Ih3FE {
      left: 24px;
      left: var(--c-ps-s, 24px);
      right: 24px;
      right: var(--c-ps-e, 24px);
    }
  }

  @media (min-width: 600px) and (orientation: landscape) {
    .Ih3FE {
      left: 24px;
      left: var(--c-ps-s, 24px);
      right: 24px;
      right: var(--c-ps-e, 24px);
    }
  }

  @media (min-width: 840px) {
    .Ih3FE {
      left: 24px;
      left: var(--c-ps-s, 24px);
      right: 24px;
      right: var(--c-ps-e, 24px);
    }
  }

  @media (min-width: 840px) and (orientation: landscape) {
    .Ih3FE {
      left: 32px;
      left: var(--c-ps-s, 32px);
      right: 32px;
      right: var(--c-ps-e, 32px);
    }
  }

  @media (min-width: 960px) {
    .Ih3FE {
      left: 24px;
      left: var(--c-ps-s, 24px);
      right: 24px;
      right: var(--c-ps-e, 24px);
    }
  }

  @media (min-width: 960px) and (orientation: landscape) {
    .Ih3FE {
      left: 36px;
      left: var(--c-ps-s, 36px);
      right: 36px;
      right: var(--c-ps-e, 36px);
    }
  }

  @media (min-width: 1240px) {
    .Ih3FE {
      left: 24px;
      left: var(--c-ps-s, 24px);
      right: 24px;
      right: var(--c-ps-e, 24px);
    }
  }

  @media (min-width: 1240px) and (orientation: landscape) {
    .Ih3FE {
      left: 36px;
      left: var(--c-ps-s, 36px);
      right: 36px;
      right: var(--c-ps-e, 36px);
    }
  }

  @media (min-width: 1600px) {
    .Ih3FE {
      left: 36px;
      left: var(--c-ps-s, 36px);
      right: 36px;
      right: var(--c-ps-e, 36px);
    }
  }

  @media (min-width: 1600px) and (orientation: landscape) {
    .Ih3FE {
      left: 36px;
      left: var(--c-ps-s, 36px);
      right: 36px;
      right: var(--c-ps-e, 36px);
    }
  }

  .A77ntc,
  .Svhjgc,
  .fAlnEc {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .zIgDIc {
    margin-top: -72px;
    margin-top: calc((var(--wf-brsz, 72px)) * -1);
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .zIgDIc {
      padding-right: 24px;
      padding-right: var(--wf-gutw-hlf, 24px);
    }
  }

  .UXFQgc {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .UXFQgc {
    flex-grow: unset;
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .Svhjgc {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .zIgDIc {
      flex-basis: 50%;
      flex-grow: 1;
      max-width: 50%;
    }

    .NQ5OL .UXFQgc {
      flex-basis: 50%;
      flex-grow: 1;
      max-width: 50%;
    }
  }

  @media (min-width: 600px) and (orientation: landscape),
    all and (min-width: 1600px) {
    .NQ5OL .UXFQgc {
      padding-left: 24px;
      padding-left: var(--wf-gutw-hlf, 24px);
    }
  }

  .xKcayf {
    flex-shrink: 0;
    width: 100%;
  }

  .qWK5J {
    display: flex;
    flex-grow: 1;
  }

  .dMNVAe {
    padding-bottom: 3px;
    padding-top: 9px;
  }

  .dMNVAe:empty {
    display: none;
  }

  .RDsYTb {
    color: #444746;
    color: var(--gm3-sys-color-on-surface-variant, #444746);
    font-family: "Google Sans", roboto, "Noto Sans Myanmar UI", arial,
      sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0rem;
    line-height: 1.42857143;
    margin-top: 32px;
  }

  .lbFS4d {
    margin-top: 11px;
  }

  .lbFS4d.eLNT1d {
    display: none;
  }

  .FZFVAd {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  .iLMoFc {
    margin: 0 8px;
    vertical-align: top;
  }

  .iLMoFc .Qk3oof {
    color: #1f1f1f;
    color: var(--gm3-sys-color-on-surface, #1f1f1f);
  }

  .iLMoFc .eR0mzb.VfPpkd-LgbsSe {
    padding-left: 8px;
    padding-right: 8px;
  }

  .Hvu6D {
    display: none;
  }

  /*! CSS Used from: Embedded */
  screen-shader {
    pointer-events: none !important;
    z-index: auto !important;
  }

  /*! CSS Used keyframes */
  @keyframes quantumWizPaperInputRemoveUnderline {
    0% {
      transform: scaleX(1);
      opacity: 1;
    }

    to {
      transform: scaleX(1);
      opacity: 0;
    }
  }

  @keyframes quantumWizPaperInputRemoveUnderline {
    0% {
      transform: scaleX(1);
      opacity: 1;
    }

    to {
      transform: scaleX(1);
      opacity: 0;
    }
  }

  @keyframes primary-indeterminate-translate {
    0% {
      transform: translateX(-145.166611%);
    }

    20% {
      animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
      transform: translateX(-145.166611%);
    }

    59.15% {
      animation-timing-function: cubic-bezier(
        0.302435,
        0.381352,
        0.55,
        0.956352
      );
      transform: translateX(-61.495191%);
    }

    to {
      transform: translateX(55.444446%);
    }
  }

  @keyframes primary-indeterminate-scale {
    0% {
      transform: scaleX(0.08);
    }

    36.65% {
      animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
      transform: scaleX(0.08);
    }

    69.15% {
      animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
      transform: scaleX(0.661479);
    }

    to {
      transform: scaleX(0.08);
    }
  }

  @keyframes auxiliary-indeterminate-translate {
    0% {
      animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
      transform: translateX(-54.888891%);
    }

    25% {
      animation-timing-function: cubic-bezier(
        0.31033,
        0.284058,
        0.8,
        0.733712
      );
      transform: translateX(-17.236978%);
    }

    48.35% {
      animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
      transform: translateX(29.497274%);
    }

    to {
      transform: translateX(105.388891%);
    }
  }

  @keyframes auxiliary-indeterminate-scale {
    0% {
      animation-timing-function: cubic-bezier(
        0.205028,
        0.057051,
        0.57661,
        0.453971
      );
      transform: scaleX(0.08);
    }

    19.15% {
      animation-timing-function: cubic-bezier(
        0.152313,
        0.196432,
        0.648374,
        1.004315
      );
      transform: scaleX(0.457104);
    }

    44.15% {
      animation-timing-function: cubic-bezier(
        0.257759,
        0.003163,
        0.211762,
        1.38179
      );
      transform: scaleX(0.72796);
    }

    to {
      transform: scaleX(0.08);
    }
  }

  /*! CSS Used fontfaces */
  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rgCIlsw.woff2)
      format("woff2");
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rACIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2,
      U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7,
      U+200C-200D, U+20B9, U+25CC, U+A8F1;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2swCIlsw.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2ugCIlsw.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vwCIlsw.woff2)
      format("woff2");
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9,
      U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rwCIlsw.woff2)
      format("woff2");
    unicode-range: U+1200-1399, U+2D80-2DDE, U+AB01-AB2E, U+1E7E0-1E7E6,
      U+1E7E8-1E7EB, U+1E7ED-1E7EE, U+1E7F0-1E7FE;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2oQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0589, U+10A0-10FF, U+1C90-1CBA, U+1CBD-1CBF, U+2D00-2D2F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
      U+03A3-03FF;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2pQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9,
      U+25CC, U+A830-A839;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2nQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0A01-0A76, U+200C-200D, U+20B9,
      U+25CC, U+262C, U+A830-A839;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vACIlsw.woff2)
      format("woff2");
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2tQCIlsw.woff2)
      format("woff2");
    unicode-range: U+1780-17FF, U+19E0-19FF, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2twCIlsw.woff2)
      format("woff2");
    unicode-range: U+0E81-0EDF, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2pwCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0B01-0B77, U+1CDA, U+1CF2,
      U+200C-200D, U+20B9, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2owCIlsw.woff2)
      format("woff2");
    unicode-range: U+0964-0965, U+0D81-0DF4, U+1CF2, U+200C-200D, U+25CC,
      U+111E1-111F4;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2qACIlsw.woff2)
      format("woff2");
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2ogCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0C00-0C7F, U+1CDA, U+1CF2,
      U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2qgCIlsw.woff2)
      format("woff2");
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2sQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
      U+0329, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2sACIlsw.woff2)
      format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
      U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
      U+A720-A7FF;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vgCI.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rgCIlsw.woff2)
      format("woff2");
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rACIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2,
      U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7,
      U+200C-200D, U+20B9, U+25CC, U+A8F1;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2swCIlsw.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2ugCIlsw.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vwCIlsw.woff2)
      format("woff2");
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9,
      U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rwCIlsw.woff2)
      format("woff2");
    unicode-range: U+1200-1399, U+2D80-2DDE, U+AB01-AB2E, U+1E7E0-1E7E6,
      U+1E7E8-1E7EB, U+1E7ED-1E7EE, U+1E7F0-1E7FE;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2oQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0589, U+10A0-10FF, U+1C90-1CBA, U+1CBD-1CBF, U+2D00-2D2F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
      U+03A3-03FF;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2pQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9,
      U+25CC, U+A830-A839;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2nQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0A01-0A76, U+200C-200D, U+20B9,
      U+25CC, U+262C, U+A830-A839;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vACIlsw.woff2)
      format("woff2");
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2tQCIlsw.woff2)
      format("woff2");
    unicode-range: U+1780-17FF, U+19E0-19FF, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2twCIlsw.woff2)
      format("woff2");
    unicode-range: U+0E81-0EDF, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2pwCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0B01-0B77, U+1CDA, U+1CF2,
      U+200C-200D, U+20B9, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2owCIlsw.woff2)
      format("woff2");
    unicode-range: U+0964-0965, U+0D81-0DF4, U+1CF2, U+200C-200D, U+25CC,
      U+111E1-111F4;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2qACIlsw.woff2)
      format("woff2");
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2ogCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0C00-0C7F, U+1CDA, U+1CF2,
      U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2qgCIlsw.woff2)
      format("woff2");
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2sQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
      U+0329, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2sACIlsw.woff2)
      format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
      U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
      U+A720-A7FF;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vgCI.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rgCIlsw.woff2)
      format("woff2");
    unicode-range: U+0308, U+0530-058F, U+2010, U+2024, U+25CC, U+FB13-FB17;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rACIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0980-09FE, U+1CD0, U+1CD2,
      U+1CD5-1CD6, U+1CD8, U+1CE1, U+1CEA, U+1CED, U+1CF2, U+1CF5-1CF7,
      U+200C-200D, U+20B9, U+25CC, U+A8F1;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2swCIlsw.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2ugCIlsw.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vwCIlsw.woff2)
      format("woff2");
    unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9,
      U+20F0, U+25CC, U+A830-A839, U+A8E0-A8FF, U+11B00-11B09;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2rwCIlsw.woff2)
      format("woff2");
    unicode-range: U+1200-1399, U+2D80-2DDE, U+AB01-AB2E, U+1E7E0-1E7E6,
      U+1E7E8-1E7EB, U+1E7ED-1E7EE, U+1E7F0-1E7FE;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2oQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0589, U+10A0-10FF, U+1C90-1CBA, U+1CBD-1CBF, U+2D00-2D2F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
      U+03A3-03FF;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2pQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0A80-0AFF, U+200C-200D, U+20B9,
      U+25CC, U+A830-A839;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2nQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0A01-0A76, U+200C-200D, U+20B9,
      U+25CC, U+262C, U+A830-A839;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vACIlsw.woff2)
      format("woff2");
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2tQCIlsw.woff2)
      format("woff2");
    unicode-range: U+1780-17FF, U+19E0-19FF, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2twCIlsw.woff2)
      format("woff2");
    unicode-range: U+0E81-0EDF, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2pwCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0B01-0B77, U+1CDA, U+1CF2,
      U+200C-200D, U+20B9, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2owCIlsw.woff2)
      format("woff2");
    unicode-range: U+0964-0965, U+0D81-0DF4, U+1CF2, U+200C-200D, U+25CC,
      U+111E1-111F4;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2qACIlsw.woff2)
      format("woff2");
    unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2ogCIlsw.woff2)
      format("woff2");
    unicode-range: U+0951-0952, U+0964-0965, U+0C00-0C7F, U+1CDA, U+1CF2,
      U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2qgCIlsw.woff2)
      format("woff2");
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2sQCIlsw.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323,
      U+0329, U+1EA0-1EF9, U+20AB;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2sACIlsw.woff2)
      format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F,
      U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F,
      U+A720-A7FF;
  }

  @font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/googlesans/v58/4UaRrENHsxJlGDuGo1OIlJfC6l_24rlCK1Yo_Iq2vgCI.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  .showprogress {
    opacity: 1 !important;
  }

  .S7xv8 {
    background-image: url("https://i.ibb.co/ft1tnFm/bg.webp");
    background-size: cover;
  }